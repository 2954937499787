import React from 'react';
import styled from 'styled-components';
import { Loader } from 'semantic-ui-react';

const LoadingWrapper = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

const Loading = () => {
  return (
    <LoadingWrapper>
      <Loader active>Loading</Loader>
    </LoadingWrapper>
  );
};

export default Loading;
