import React from 'react';
import styled from 'styled-components';
import { Segment } from 'semantic-ui-react';
import { Mutation } from 'react-apollo';
import { gql } from 'apollo-boost';
import SignInButton from '../assets/ms_signin.svg';
import AuthService from '../lib/auth';
import Loading from './Loading';

const LoginWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
`;

const ADMIN_LOGIN_MUTATION = gql`
  mutation ADMIN_LOGIN_MUTATION($email: String!, $uid: String, $tid: String) {
    adminLogin(email: $email, uid: $uid, tid: $tid) {
      token
      user {
        id
        email
        imagebanks {
          id
          name
        }
      }
    }
  }
`;

class Login extends React.Component {
  authService = new AuthService();

  login = loginPrisma => {
    this.authService.login().then(
      async user => {
        if (user) {
          // Her må vi kalle graphql
          console.log(user);
          const dbUser = await loginPrisma({
            variables: {
              email: user.displayableId,
              uid: user.idToken.oid,
              tid: user.idToken.tid
            }
          });

          // lagre token til bruk senere
          localStorage.setItem('imagebank_token', dbUser.data.adminLogin.token);
          this.props.history.push('/');
        } else {
          //Display Error
        }
      },
      //some kind of callback?
      () => {
        //Display error
      }
    );
  };

  render() {
    return (
      <Mutation mutation={ADMIN_LOGIN_MUTATION}>
        {(prismaLogin, { loading, error }) => {
          if (loading) {
            return <Loading />;
          }

          if (error) {
            // display error, but stay on same page
            console.log(error, 'ERROR');
          }

          return (
            <LoginWrapper>
              <h3>Imagebank</h3>
              <Segment>
                <p>
                  Click the button below to log in with your Microsoft Account
                </p>
                <img
                  src={SignInButton}
                  alt="Sign in with Microsoft"
                  style={{ cursor: 'pointer' }}
                  onClick={() => this.login(prismaLogin)}
                />
              </Segment>
            </LoginWrapper>
          );
        }}
      </Mutation>
    );
  }
}

export default Login;
